




















import { i18n } from '@/main';
import { DynamicTranslationService } from '@/services/DynamicTranslationService';
import { Component, Vue } from 'vue-property-decorator'
import SongDetails from './../Shared/SongDetails.vue';

let previousSearch = '';

@Component({
  metaInfo: () => ({
    title: DynamicTranslationService.songDetailsTitle
  }), 
  components:{ 
    SongDetails
  }, 
  beforeRouteEnter: (to, from, next) => { 
    previousSearch = from.query.s as string || '';
    next();
  }
})
export default class SongDetail extends Vue {
  get prevSearch() { 
    return previousSearch;
  }
}
